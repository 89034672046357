import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import orange from "@mui/material/colors/orange";
import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import React from "react";
import {
  createMakeStyles,
  createWithStyles,
  TssCacheProvider,
} from "tss-react";

// declare module "@mui/material/styles/createPalette" {
declare module "@mui/material/styles" {
  interface Palette {
    validation: {
      success: PaletteColor;
      warn: PaletteColor;
    };
  }

  interface PaletteOptions {
    validation: {
      success: PaletteColorOptions;
      warn: PaletteColorOptions;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#616161",
    },
    secondary: orange,
    validation: {
      success: {
        main: "#aed581",
      },
      warn: {
        main: "#fdd835",
      },
    },
    background: {
      default: "#f9f8f8",
      paper: "rgb(255, 255, 255)",
    },
  },
});

// export const { makeStyles, useStyles } = createMakeStyles({ useTheme: () => theme });
export const { makeStyles, useStyles } = createMakeStyles<typeof theme>({
  useTheme,
});
export const { withStyles } = createWithStyles({ useTheme });

export const muiCache = createCache({
  key: "css",
  prepend: true,
});
export const tssCache = createCache({
  key: "tss",
  prepend: false,
});

export function Theme(props: React.PropsWithChildren<{}>) {
  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <ThemeProvider theme={theme} children={props.children} />
      </TssCacheProvider>
    </CacheProvider>
  );
}
