export async function sendMail(text: string, html?: string) {
  let myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic YXBpOmVhZmVkOTk4MjliNjY4Yzg3MjlkYTk1ZTAzZjNhYmUwLTc1Y2Q3ODRkLTA5MWFlNzYw"
  );

  let formdata = new FormData();
  // formdata.append(
  //   "from",
  //   "mailgun@sandbox2fbf25af71494d03b46a723bbca67d04.mailgun.org"
  // );
  formdata.append("from", "agv@motorhammer.com");
  formdata.append("to", "info@motorhammer.com");
  formdata.append("subject", "Anfrage via AutoGutVerkaufen.de");

  formdata.append("text", text);
  if (html) formdata.append("html", html);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    // redirect: 'follow'
  };

  return await fetch(
    "https://api.eu.mailgun.net/v3/mail.motorhammer.com/messages",
    requestOptions
  );
  // .then(response => response.text())
  // .then(result => console.log(result))
  // .catch(error => console.log('error', error));
}
