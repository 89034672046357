import { Link } from "@mui/material";
import { Box } from "@mui/system";
import { AppPages } from "./App";

export function Impressum(props: { setContent: (s: AppPages) => void }) {
  return (
    <Box sx={{ textAlign: "left" }}>
      <h1>Impressum</h1>
      <h4>Angaben gemäß § 5 TMG:</h4>
      <p>
        AutoGutVerkaufen.de ist ein Angebot der
        <br />
        MotorHammer GmbH
        <br />
        Höhenberg 10
        <br />D - 83132 Pittenhart
      </p>
      <p>
        Telefon: <a href="tel:+4989716772370">+49 (0)89 / 716772370</a>
        <br />
        Email:{" "}
        <a href="mailto:contact@motorhammer.com">contact@motorhammer.com</a>
        <br />
        Web: <a href="https://www.motorhammer.com">motorhammer.com</a>
        <br />
      </p>
      <p>
        <b>Handelsregister</b>
        <br />
        Hauptsitz: Pittenhart
        <br />
        Amtsgericht Traunstein
        <br />
        HRB 27664
        <br />
        USt-ID: DE322279803
      </p>{" "}
      <p>
        <b>Geschäftsführer</b>
        <br />
        Felix Hartmann
        <br />
        David Toussaint
      </p>
    </Box>
  );
}
