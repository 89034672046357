import React from "react";

import Image from "./img/blurry-soft-background.jpg";
import { Link, Paper } from "@mui/material";
import { makeStyles, Theme } from "./Theme";
// import { Copyright, MHBanner, SwitchLanguageIconBox } from "../commonControls";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AppPages } from "./App";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()((theme) => ({
  backgroundContainer: {
    backgroundImage: `url(${Image})`,
    // height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    // overflow: "auto",
    overflow: "hidden",
    width: "100vw",
    // minHeight: "600px",
    minHeight: "100vh",
    position: "absolute",
    top: "0px",
    left: "0px",
    m: 0,
    p: 0,
  },
  paperContainer: {
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: "yellow",
      minHeight: "100vh",
      // height: "100%",
      overflow: "hidden",

      borderRadius: 0,
      marginTop: 0,
      marginBottom: "10px",
    },
    // [theme.breakpoints.up("md")]: {
    //   // backgroundColor: "red",
    //   // height: "100vh",
    //   minHeight: "500px",
    //   marginTop: "20px",
    //   marginBottom: "80px"
    // },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
    background: "white",

    padding: theme.spacing(5),
    // width: "500px",
    // height:'100%',
    minHeight: "500px",
    maxWidth: "100%",

    marginTop: "20px",
    marginBottom: "80px",
  },
  title: {
    fontWeight: "bold",
  },
  footerBox: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: "flex",
    background: "black",
    justifyContent: "center",
    alignItems: "center",
  },
  footerLink: {
    color: "whitesmoke",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function CarBackgroundBoxLayout(props: {
  title: React.ReactNode;
  setContent: (s: AppPages) => void;
  content?: AppPages;
  children: React.ReactNode;
}) {
  const { classes } = useStyles();
  // const ref = React.useRef<any>(null);
  const { children, title, setContent, content } = props;

  return (
    <Grid
      className={classes.backgroundContainer}
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sm={8} md={6}>
        <Paper className={classes.paperContainer} elevation={6}>
          {/* <MHBanner /> */}
          {content !== "Form" && (
            <Link
              sx={{ float: "right", cursor: "pointer" }}
              onClick={() => setContent("Form")}
            >
              <CloseIcon />
            </Link>
          )}
          <Box
            display="flex"
            marginBottom={1}
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              align="center"
              className={classes.title}
              component="h1"
              variant="h4"
            >
              {title}
            </Typography>
          </Box>
          {children}
        </Paper>
        <Box className={classes.footerBox}>
          <Link
            className={classes.footerLink}
            onClick={() => setContent("Impressum")}
          >
            Impressum
          </Link>
          <Link
            className={classes.footerLink}
            onClick={() => setContent("Privacy")}
          >
            Datenschutzerklärung
          </Link>
          {/* <SwitchLanguageIconBox /> <Copyright /> */}
        </Box>
      </Grid>
    </Grid>
  );
}
