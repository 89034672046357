import { Box } from "@mui/system";
import { AppPages } from "./App";

export function Privacy(props: { setContent: (s: AppPages) => void }) {
  return (
    <Box sx={{ textAlign: "left" }}>
      {/* <h1>Datenschutzerklärung</h1> */}
      <h6>
        Verantwortliche Stelle im Sinne der EU-Datenschutzgrundverordnung
        (DSGVO) ist:
        <br />
        MotorHammer GmbH, <br />
        vertreten durch die Geschäftsführer <br />
        Herrn Felix Hartmann und <br />
        Herrn David Toussaint,
        <br />
        <br />
        Höhenberg 10
        <br />
        D - 83132 Pittenhart
        <br />
        contact@motorhammer.com
        <br />
        www.motorhammer.com <br />
      </h6>

      <h5>Ihre Betroffenenrechte</h5>
      <p>
        Sie können uns jederzeit unter den angegebenen Kontaktdaten kontaktieren
        und folgende Rechte ausüben:
        <ul>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung,
          </li>
          <li>Berichtigung unrichtiger personenbezogener Daten,</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen,
          </li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt oder einen Vertrag mit uns abgeschlossen haben.
          </li>
        </ul>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
        jederzeit mit Wirkung für die Zukunft widerrufen. Sie können sich
        jederzeit mit einer Beschwerde an die für Sie zuständige
        Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich
        nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen
        Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
        Bereich) mit Anschrift finden Sie unter:{" "}
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </p>

      <h5>Zugriffsdaten und Hosting</h5>
      <p>
        Sie können unsere Webseite besuchen, ohne Angaben zu Ihrer Person zu
        machen. Bei jedem Aufruf einer Webseite speichert der Webserver
        lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen
        der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs,
        übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten)
        enthält und den Abruf dokumentiert. Diese Zugriffsdaten werden
        ausschließlich zum Zwecke der Sicherstellung eines störungsfreien
        Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet.
        Dies dient gemäß Art. 6 Abs. 1 f) DSGVO der Wahrung unserer im Rahmen
        einer Interessensabwägung überwiegenden berechtigten Interessen an einer
        korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden
        spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
      </p>

      <h5>
        Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte
      </h5>
      <p>
        Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zu dem Zweck, auf
        unserer Plattform als Händler Verkaufsangebote einzustellen oder als
        Käufer (Reseller) Gebote abzugeben und Waren zu ersteigern. Zu diesem
        Zweck ist eine Registrierung erforderlich (siehe Registrierung auf
        unserer Webseite). Diese Daten werden verarbeitet, um Ihr Nutzungsprofil
        zu erstellen, Ihnen die erforderlichen Zugangsdaten zukommen zu lassen,
        etwaige Kosten abzurechnen und Kontakt mit Ihnen aufzunehmen.
        Rechtsgrundlage dieser Verarbeitung ist der mit Ihnen geschlossene
        Vertrag gemäß unserer{" "}
        <a href="/legal/agb" target="_blank">
          Allgemeinen Geschäftsbedingungen
        </a>{" "}
        nach Art. 6 Abs. 1 b) DSGVO sowie die von Ihnen erteilte Einwilligung
        nach Art. 6 Abs. 1 a) DSGVO. Ihre Daten bleiben so lange gespeichert wie
        Ihr Profil besteht, wir nach dem mit Ihnen geschlossenen Vertrag zur
        Speicherung berechtigt sind, z.B. aus Abrechnungsgründen, oder wir aus
        gesetzlichen Gründen zu einer Aufbewahrung Ihrer Daten verpflichtet
        sind. Sie können Ihr Profil jederzeit löschen.
      </p>
      <p>
        Der Verkäufer wird bei der Einstellung eines Verkaufsangebots nicht
        namentlich genannt und ist für andere registrierte Benutzer nur mit
        Standortangabe und Postleitzahl sichtbar (Beispiel: „80331 München“),
        damit dieser vom potentiellen Käufer (Reseller) nicht ermittelt werden
        kann. Zu beachten ist aber, dass gegebenenfalls die Identität des
        Verkäufers anhand von Fahrzeugdaten wie z.B. der Fahrgestellnummer,
        eines Fotos oder aufgrund der Summe der angegeben Fahrzeugdaten
        ermittelt werden kann. Geben Sie somit als Verkäufer keine Daten
        bekannt, die Rückschlüsse auf Ihre Identität zulassen. Der von einem
        potentiellen Käufer (Reseller) vergebene Username wird bei der
        Einstellung eines Gebots veröffentlicht und ist für andere registrierte
        Benutzer, also sowohl für den Verkäufer als auch für andere Reseller,
        sichtbar.
      </p>
      <p>
        Um Ihre Kaufmannseigenschaft überprüfen zu können, können wir eine Kopie
        Ihres Gewerberegister- oder Handelsregistereintrags sowie Ihres
        Personal- oder Reisepasses anfordern. Dies ist aufgrund unserer
        vertraglichen Beziehung nach Art. 6 Abs. 1 b) DSGVO und aus berechtigtem
        Interesse nach Art. 6 Abs. 1 f) DSGVO gerechtfertigt, um Missbrauch zu
        vermeiden und nur solche Benutzer zuzulassen, die Kaufmann im Sinne der
        handelsrechtlichen Vorschriften sind.
      </p>
      <p>
        Im Falle einer beendeten Auktion werden die vollständigen Kontaktdaten
        von Verkäufer und Käufer als Höchstbietendem in einem nur für
        registrierte Benutzer sichtbaren Bereich unter „Beendete Auktionen“ bzw.
        „Gewonnene Auktion“ veröffentlicht.
      </p>
      <p>
        Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
        Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
        persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet
        nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter,
        wenn:
      </p>
      <ul>
        <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
        <li>
          die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich
          ist,
        </li>
        <li>
          die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
          erforderlich ist,
        </li>
      </ul>

      <p>
        die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist
        und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
        schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
      </p>
      <h5>Löschung bzw. Sperrung der Daten</h5>

      <p>
        Wir halten uns an die Grundsätze der Datenvermeidung und
        Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur
        so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich
        ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen
        Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw.
        Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und
        entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
      </p>
      <h5>Cookies</h5>
      <p>
        Wie viele andere Webseiten verwenden auch wir so genannte „Cookies“.
        Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre
        Festplatte übertragen werden. Hierdurch erhalten wir automatisch
        bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser,
        Betriebssystem und Ihre Verbindung zum Internet.
      </p>
      <p>
        Cookies können nicht verwendet werden, um Programme zu starten oder
        Viren auf einen Computer zu übertragen. Anhand der in Cookies
        enthaltenen Informationen können wir Ihnen die Navigation erleichtern
        und die korrekte Anzeige unserer Webseiten ermöglichen.
      </p>
      <p>
        In keinem Fall werden die von uns erfassten Daten an Dritte
        weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
        personenbezogenen Daten hergestellt. Natürlich können Sie unsere Website
        grundsätzlich auch ohne Cookies betrachten. Internet-Browser sind
        regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen
        können Sie die Verwendung von Cookies jederzeit über die Einstellungen
        Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen
        Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen
        ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer
        Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von
        Cookies deaktiviert haben.
      </p>

      <h5>Registrierung auf unserer Webseite</h5>

      <p>
        Bei der Registrierung für die Nutzung unserer personalisierten
        Leistungen werden einige personenbezogene Daten erhoben, wie Name,
        Anschrift, Firma, Kontakt- und Kommunikationsdaten wie Telefonnummer und
        E-Mail-Adresse. Außerdem können Sie ein Foto hochladen und einen
        Usernamen und ein Passwort vergeben, das nur Ihnen bekannt ist und mit
        dem Sie sich nach erfolgreicher Registrierung jederzeit auf unserer
        Plattform als registrierter User einloggen können. Sind Sie bei uns
        registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir
        nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die
        Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten
        jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen wir
        Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie
        gespeicherten personenbezogenen Daten. Gerne berichtigen bzw. löschen
        wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen
        Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem
        Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung
        angegebenen Kontaktdaten.
      </p>
      <h5>SSL-Verschlüsselung</h5>

      <p>
        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
        wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
      </p>
      <h5>Kommentarfunktion / Bewertungen</h5>

      <p>
        Wenn Sie Kommentare oder Bewertungen auf unserer Website hinterlassen,
        wird neben diesen Angaben auch der Zeitpunkt ihrer Erstellung und der
        zuvor durch Sie gewählte Nutzername gespeichert und veröffentlicht. Dies
        dient unserer Sicherheit, da wir für widerrechtliche Inhalte auf unserer
        Webseite belangt werden können, auch wenn diese durch Sie erstellt
        wurden. Rechtsgrundlage für diese Verarbeitung Ihrer personenbezogenen
        Daten ist Ihre Einwilligung nach Art. 6 Abs. 1 a) DSGVO.
      </p>
      <h5>WhatsApp-Benachrichtigung</h5>

      <p>
        Im Rahmen der Benachrichtigungs-Funktion ermöglichen wir es Nutzern,
        Benachrichtigungen über den Messanger-Dienst der Firma WhatsApp Inc.,
        650 Castro Street, Suite 120-219, Mountain View, Kalifornien 94041,
        Vereinigte Staaten von Amerika (USA) – nachfolgend kurz „WhatsApp“ –, zu
        erhalten. Die Benachrichtigung dient dazu, Sie darüber zu informieren,
        wenn z.B. eine neue Auktion gestartet oder Ihr bisheriges Gebot im
        Rahmen einer laufenden Auktion von einem anderen Nutzer überboten wurde.
        Die Benachrichtigung über WhatsApp erfolgt nur, wenn Sie damit
        ausdrücklich einverstanden sind und den Button zur Benachrichtigung über
        WhatsApp aktiv angeklickt haben. Rechtsgrundlage für die Verarbeitung
        Ihrer Daten über WhatsApp ist somit Ihre ausdrückliche Einwilligung nach
        Art. 6 Abs. 1 a) DSGVO. Wir weisen ausdrücklich darauf hin, dass die
        Benutzung von WhatsApp keine sichere Datenübertragung gewährleistet und
        Dritte, insbesondere in den USA, auf Ihre Daten zugreifen können. Wir
        haben keinerlei Einfluss auf den Umfang der Datenverarbeitung durch
        WhatsApp. Weitere Informationen entnehmen Sie bitte der
        Datenschutzerklärung von WhatsApp unter{" "}
        <a href="https://www.whatsapp.com/security/" target="_blank">
          https://www.whatsapp.com/security/
        </a>
      </p>
      <div>
        <h5>Newsletter</h5>

        <p>
          Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung, übersenden
          wir Ihnen regelmäßig unseren Newsletter mit aktuellen Neuigkeiten und
          Angeboten bzw. vergleichbare Informationen per E-Mail an Ihre
          angegebene E-Mail-Adresse. Rechtsgrundlage ist Ihre Einwilligung nach
          Art. 6 Abs. 1 a) DSGVO.
        </p>
        <p>
          Für den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse
          ausreichend. Bei der Anmeldung zum Bezug unseres Newsletters werden
          die von Ihnen angegebenen Daten ausschließlich für diesen Zweck
          verwendet. Abonnenten können auch über Umstände per E-Mail informiert
          werden, die für den Dienst oder die Registrierung relevant sind
          (Beispielsweise Änderungen des Newsletterangebots oder technische
          Gegebenheiten). Für eine wirksame Registrierung benötigen wir eine
          valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung
          tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir
          das „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die
          Bestellung des Newsletters, den Versand einer Bestätigungsmail und den
          Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht
          erhoben. Die Daten werden ausschließlich für den Newsletterversand
          verwendet und nicht an Dritte weitergegeben.
        </p>
        <p>
          Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
          Nutzung für den Newsletterversand können Sie jederzeit widerrufen. In
          jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem
          können Sie sich jederzeit auch direkt auf dieser Webseite abmelden
          oder uns Ihren entsprechenden Wunsch über die am Ende dieser
          Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
        </p>
      </div>
      <h5>Sonstige Benachrichtigungen per E-Mail und SMS</h5>
      <p>
        Sind Sie bei uns als Nutzer registriert, informieren wir Sie im Rahmen
        des mit Ihnen geschlossenen Vertrages als besonderen Service regelmäßig
        über neu eingestellte Auktionen oder dann, wenn Ihr Gebot im Rahmen
        einer laufenden Auktion von einem anderen Reseller überboten wurde.
        Diese Benachrichtigungen können Sie in Ihrem Nutzerprofil in Ihren
        Einstellungen oder in der jeweiligen Benachrichtigung selbst jederzeit
        deaktivieren. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist somit
        der mit Ihnen geschlossene Vertrag nach Art. 6 Abs. 1 b) DSG-VO.
      </p>

      <h5>Verwendung von Scriptbibliotheken (Google Webfonts)</h5>

      <p>
        Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
        darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und
        Schriftbibliotheken wie z. B. Google Webfonts (
        <a href="https://www.google.com/webfonts/" target="_blank">
          https://www.google.com/webfonts/
        </a>
        ). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache
        Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht
        unterstützt oder den Zugriff unterbindet, werden Inhalte in einer
        Standardschrift angezeigt.
      </p>
      <p>
        Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
        automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist
        es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu
        welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten
        erheben. Die Datenschutzrichtlinie des Bibliothekbetreibers Google
        finden Sie hier:{" "}
        <a href="https://www.google.com/policies/privacy/" target="_blank">
          https://www.google.com/policies/privacy/
        </a>
      </p>
      <div>
        <h5>Google AdWords</h5>
        <p>
          Unsere Webseite nutzt das Google Conversion-Tracking. Sind Sie über
          eine von Google geschaltete Anzeige auf unsere Webseite gelangt, wird
          von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie
          für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von
          Google geschaltete Anzeige klickt. Diese Cookies verlieren nach 30
          Tagen ihre Gültigkeit und dienen nicht der persönlichen
          Identifizierung. Besucht der Nutzer bestimmte Seiten unserer Website
          und das Cookie ist noch nicht abgelaufen, können wir und Google
          erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser
          Seite weitergeleitet wurde. Jeder Google AdWords-Kunde erhält ein
          anderes Cookie. Cookies können somit nicht über die Websites von
          AdWords-Kunden nachverfolgt werden. Die mithilfe des
          Conversion-Cookies eingeholten Informationen dienen dazu,
          Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
          Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen.
        </p>
        <p>
          Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür
          erforderliche Setzen eines Cookies ablehnen – etwa per
          Browser-Einstellung, die das automatische Setzen von Cookies generell
          deaktiviert oder Ihren Browser so einstellen, dass Cookies von der
          Domain „googleleadservices.com“ blockiert werden. Bitte beachten Sie,
          dass Sie die Opt-out-Cookies nicht löschen dürfen, solange Sie keine
          Aufzeichnung von Messdaten wünschen. Haben Sie alle Ihre Cookies im
          Browser gelöscht, müssen Sie das jeweilige Opt-out Cookie erneut
          setzen.
        </p>
      </div>
      <h5>Widerspruchsrecht</h5>
      <p>
        Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung
        überwiegenden berechtigten Interessen personenbezogene Daten wie oben
        erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für
        die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des
        Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben
        ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen
        ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer
        besonderen Situation ergeben, zu.
      </p>
      <p>
        Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen
        Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir
        können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
        die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die
        Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen dient.
      </p>
      <p>
        Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings
        erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu
        diesem Zweck verarbeiten.
      </p>

      <h5>Änderung unserer Datenschutzbestimmungen</h5>
      <p>
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
        stets den aktuellen rechtlichen Anforderungen entspricht oder um
        Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
        z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
        dann die neue Datenschutzerklärung.
      </p>
      <h5>Fragen zum Datenschutz</h5>
      <p>
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
        E-Mail an{" "}
        <a href="mailto:contact@motorhammer.com">contact@motorhammer.com</a>.
      </p>
    </Box>
  );
}
