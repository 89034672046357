import React from "react";
import logo from "./logo.svg";
import { Box } from "@mui/system";

import "./App.css";
import {
  Button,
  capitalize,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  Paper,
  stepContentClasses,
  Typography,
} from "@mui/material";
import { CarBackgroundBoxLayout } from "./CarBackgroundBoxLayout";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { sendMail } from "./MailGun";
import { Impressum } from "./Impressum";
import { Privacy } from "./Privacy";
import { makeStyles } from "./Theme";
import { classnames } from "tss-react/tools/classnames";

interface FormValues {
  name: string;
  email: string;
  phone: string;
  maker: string;
  model: string;
  mileage: string;
  initialRegistration: string;
}

const useStyles = makeStyles()((theme) => ({
  formContainerHalf: {
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: "red",
      m: 2,
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      // backgroundColor: "yellow",
      m: 2,
      width: "45%",
    },
  },
  paperContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 0,
      width: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      padding: 3,
      // backgroundColor: "green",
      maxWidth: "500px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // padding: 3,

    width: "fit-content",
    backgroundColor: "white",
  },
  formContainerFull: {
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: "red",
      m: 2,
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      // backgroundColor: "yellow",
      m: 2,

      width: "94%",
    },
  },
}));

export type AppPages = "Form" | "Impressum" | "Privacy";
function App() {
  const [sendStatus, setSendStatus] = React.useState<
    "success" | "error" | undefined
  >();
  const [content, setContent] = React.useState<AppPages>("Form");
  const { classes } = useStyles();
  const generateProposal = (data: FormValues) => {
    // console.log("🚀 ~ data", data);
    // console.log("🚀 ~ 'generate Proposal'", proposal);
    let html = "Es ist eine Anfrage von AutoGutVerkaufen.de eingegangen.";
    html += `<hr />`;
    html += `<h4>Persönliche Daten</h4>`;
    html += `<br /><strong>Name:</strong>  ${data.name}`;
    html += `<br /><strong>Phone:</strong> <a href="tel:${data.phone}">${data.phone}</a></small>`;
    html += `<br /><strong>eMail:</strong> <a href="mailto:${data.email}">${data.email}</a></small>`;
    html += `<hr />`;
    html += `<h4>Fahrzeug</h4>`;
    html += `<strong>Marke:</strong> ${data.maker}`;
    html += `<br /><strong>Model:</strong> ${data.model}`;
    html += `<br /><strong>Erstzulassung:</strong> ${data.initialRegistration}`;
    html += `<br /><strong>KM Stand:</strong> ${data.mileage}`;

    // html += `<h5>Total Budget: ${proposal.budget.toLocaleString()} XAF</h6><br />`;
    // html += `<label>Budget Breakdown:</label> `;
    // html += `<table style="width: 500px; border:1px solid grey; padding:10px"><tr><td>Category</td><td>Priority</td><td>Budget (XAF)</td>`;
    // const proposalTable = proposal.eventType.eventCategories.map((category) => {
    //   // const category = proposal.categories[key as keyof EventCategories]
    //   console.log("cat.value", category.value);
    //   return `<tr><td>${capitalize(category.title)}</td><td>${
    //     prioMapping[category.priority]
    //   }</td><td>${category.value?.toLocaleString()} XAF</td></tr>`;
    // });
    // html += proposalTable.join("");

    html += "</table>";

    sendProposal(html);
  };

  const sendProposal = async (html: string) => {
    console.log(
      "🚀 ~ file: ResultCard.tsx ~ line 61 ~ sendProposal ~ 'Sending Proposal'",
      "Sending Proposal"
    );
    const sendRes = await sendMail("test", html);
    if (sendRes.status === 200) {
      console.log("🚀 ~ sendRes", sendRes);
      setSendStatus("success");
    }
  };
  // const [formValues, setFormValues] = React.useState({
  //   name: {
  //     value: "",
  //     error: false,
  //     errorMessage: "You must enter a name",
  //   },
  //   email: {
  //     value: "",
  //     error: false,
  //     errorMessage: "Bitte geben Sie eine eMail an.",
  //   },
  //   phone: {
  //     value: "+49 ",
  //     error: false,
  //     errorMessage: "You must enter an age",
  //   },
  //   maker: {
  //     value: "",
  //     error: false,
  //     errorMessage: "You must enter your liked tech stacks",
  //   },
  //   model: {
  //     value: "full-stack",
  //     error: false,
  //     errorMessage: "You must choose your job title",
  //   },
  //   mileage: {
  //     value: "full-stack",
  //     error: false,
  //     errorMessage: "You must choose your job title",
  //   },
  //   initialRegistration: {
  //     value: "full-stack",
  //     error: false,
  //     errorMessage: "You must choose your job title",
  //   },
  // });
  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: {
  //       //@ts-ignore
  //       ...formValues[name],
  //       value,
  //     },
  //   });
  // };
  // const handleSubmit = (e: any) => {
  //   e.preventDefault();

  //   const formFields = Object.keys(formValues);
  //   let newFormValues = { ...formValues };

  //   for (let index = 0; index < formFields.length; index++) {
  //     const currentField: keyof FormValues = formFields[
  //       index
  //     ] as keyof FormValues;
  //     const currentValue = formValues[currentField].value;

  //     if (currentValue === "") {
  //       newFormValues = {
  //         ...newFormValues,
  //         [currentField]: {
  //           ...newFormValues[currentField],
  //           error: true,
  //         },
  //       };
  //     }
  //   }

  //   setFormValues(newFormValues);
  // };
  let title = "";
  switch (content) {
    case "Privacy":
      title = "Datenschutzerklärung";
      break;
    case "Form":
      title = "AutoGutVerkaufen.de";
      break;
    case "Impressum":
      title = "Impressum";
      break;

    default:
      title = "AutoGutVerkaufen.de";
      break;
  }
  return (
    <div className="App">
      <CarBackgroundBoxLayout
        setContent={setContent}
        content={content}
        title={title}
      >
        {content === "Form" &&
          ContactForm(sendStatus, generateProposal, classes)}
        {content === "Impressum" && <Impressum setContent={setContent} />}
        {content === "Privacy" && <Privacy setContent={setContent} />}
      </CarBackgroundBoxLayout>
    </div>
  );
}

export default App;
function ContactForm(
  sendStatus: string | undefined,
  generateProposal: (data: FormValues) => void,
  classes: ReturnType<typeof useStyles>["classes"]
) {
  return (
    <Box>
      <Box
        mb={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <Paper sx={{}} className={classes.paperContainer} elevation={0}>
          {sendStatus === undefined && (
            <Box>
              <Typography
                variant="body1"
                fontWeight={"bolder"}
                marginBottom={3}
              >
                Bitte legen Sie Ihr Auto an.
              </Typography>
              <Typography paragraph>
                Die Anfrage ist für Sie kostenlos.
                <br />
                Wir werden Sie mit weiteren Informationen kontaktieren.
              </Typography>

              <FormContainer
                // handleSubmit={handleSubmit}
                onSuccess={generateProposal}
              >
                <Box
                  sx={{
                    "& > :not(style)": { m: 1 },
                  }}
                >
                  <Typography variant="overline" display={"block"}>
                    Persönliche Daten
                  </Typography>

                  <FormControl className={classes.formContainerHalf}>
                    <TextFieldElement
                      label="Name"
                      name="name"
                      aria-describedby="name-helper-text"
                    />
                  </FormControl>

                  <FormControl className={classes.formContainerHalf}>
                    {/* <InputLabel htmlFor="phone">Phone</InputLabel> */}
                    <TextFieldElement
                      label="Tel."
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      name="phone"
                      id="phone"
                      aria-describedby="phone-helper-text"
                    />
                  </FormControl>
                  <FormControl className={classes.formContainerFull}>
                    {/* <InputLabel htmlFor="email">Email address</InputLabel> */}
                    {/* <TextValidator></TextValidator> */}
                    <TextFieldElement
                      label="eMail"
                      required
                      // error={formValues.email.error}
                      name="email"
                      id="email"
                      parseError={(e) => "Bitte geben Sie eine eMail ein"}
                    />
                    {/* <FormHelperText id="email-helper-text">
                  {}
                </FormHelperText> */}
                  </FormControl>
                  {/* <Divider sx={{ mt: 1, mb: 3 }} /> */}
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    "& > :not(style)": { m: 1 },
                  }}
                >
                  <Typography variant="overline" display={"block"}>
                    Auto Daten
                  </Typography>
                  <FormControl className={classes.formContainerHalf}>
                    {/* <InputLabel htmlFor="Guests">Guests</InputLabel> */}
                    <TextFieldElement
                      label="Marke"
                      name="maker"
                      parseError={(e) => "Bitte geben Sie eine Marke ein"}
                      id="maker"
                      aria-describedby="maker-helper-text"
                    />
                    <FormHelperText id="maker-helper-text"></FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formContainerHalf}>
                    {/* <InputLabel htmlFor="Place">Place</InputLabel> */}
                    <TextFieldElement
                      label="Modell"
                      name="model"
                      parseError={(e) => "Bitte geben Sie ein Model ein"}
                      id="model"
                      aria-describedby="model-helper-text"
                    />
                    <FormHelperText id="model-helper-text"></FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formContainerHalf}>
                    {/* <InputLabel htmlFor="Place">Place</InputLabel> */}
                    <TextFieldElement
                      rows={3}
                      label="Km-Stand"
                      name="mileage"
                      id="mileage"
                      aria-describedby="mileage-helper-text"
                    />
                    {/* <FormHelperText id="mileage-helper-text"></FormHelperText> */}
                  </FormControl>
                  <FormControl className={classes.formContainerHalf}>
                    {/* <InputLabel htmlFor="Place">Place</InputLabel> */}
                    <TextFieldElement
                      rows={3}
                      label="Erstzulassung"
                      name="initialRegistration"
                      id="initialRegistration"
                      aria-describedby="initialRegistration-helper-text"
                    />
                    {/* <FormHelperText id="initialRegistration-helper-text"></FormHelperText> */}
                  </FormControl>
                </Box>
                <Divider sx={{ mt: 1, mb: 3 }} />
                <Box>
                  <Button
                    disabled={sendStatus !== undefined}
                    type={"submit"}
                    variant={"outlined"}
                    color={"primary"}
                    sx={{ marginBottom: 2 }}
                  >
                    Anfrage abschicken
                  </Button>
                  <br />
                  <Typography variant="caption">oder</Typography>
                  <br />
                  <Button
                    sx={{ marginTop: 1 }}
                    type="button"
                    variant={"outlined"}
                    href="https://form.asana.com/?k=9FO6QeKpxA5ZZFQmdXjxcA&d=715204343484969"
                    target={"_blank"}
                  >
                    Detail-Inserat erstellen
                  </Button>
                </Box>
              </FormContainer>
            </Box>
          )}
          {sendStatus === "success" && (
            <Box>
              <Typography variant="body1" fontWeight={"bolder"} gutterBottom>
                Vielen Dank für Ihre Anfrage.
              </Typography>
              <Typography variant="caption">
                Wir werden in kürze mit Ihnen in Kontakt treten.
              </Typography>
            </Box>
          )}
          {sendStatus === "error" && (
            <Box>
              <Typography variant="body1" fontWeight={"bolder"} gutterBottom>
                Leider ist ein Fehler aufgetreten.
              </Typography>
              <Typography variant="caption">
                Bitte versuchen Sie es später erneut.
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
